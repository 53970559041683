import Alpine from 'alpinejs';
import {
    storageGet,
    storageHas,
    storageSet,
} from './local-storage';

const COOKIE_CONSENT_NAME = 'cookieConsent:accepted';

Alpine.data('cookieConsent', () => ({
    open: false,

    /**
     * On init, check whether to open cookie banner (which the help of a
     * variable in local storage)
     */
    init() {
        if (!storageHas(COOKIE_CONSENT_NAME) || (storageHas(COOKIE_CONSENT_NAME) && storageGet(COOKIE_CONSENT_NAME === false))) {
            this.open = true;
        }
    },

    /**
     * Toggles the opening and closing of the cookie consent banner and saves
     * the state in local storage
     */
    toggle() {
        this.open = !this.open;
        storageSet(COOKIE_CONSENT_NAME, !this.open);
    },
}));
