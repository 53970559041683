import Alpine from 'alpinejs';
import axios from 'axios';

Alpine.data('contactForm', () => ({
    message: {
        success: '',
        failed: {
            current: '',
            incomplete: '',
            notSent: '',
        }
    },

    success: false,
    failed: false,

    errorFromEmail: [],
    errorMessage: [],

    init() {
        this.message.success = this.$el.dataset.messageSuccess;
        this.message.failed.incomplete = this.$el.dataset.messageFailedIncomplete;
        this.message.failed.notSent = this.$el.dataset.messageFailedNotSent;
    },

    async submit($event) {
        // Reset errors after submitting
        this.success = false;
        this.failed = false;
        this.message.failed.current = '';
        this.errorFromEmail = [];
        this.errorMessage = [];

        try {
            // Send the form
            const response = await axios.post('/', new FormData($event.target));

            if (response.data.errors) {
                // response.error will be an object containing any validation errors that occurred, indexed by field name
                // e.g. response.error.fromName => ['From Name is required']

                this.failed = true;
                this.message.failed.current = this.message.failed.incomplete;

                this.errorFromEmail = response.data.errors.fromEmail ?? [];
                this.errorMessage = response.data.errors.message ?? [];
            } else {
                this.success = true;
            }
        } catch (error) {
            this.failed = true;
            this.message.failed.current = this.message.failed.notSent;

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error(`An error has occurred when sending the form: ${error.response.status} ${error.response.data.error}`)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.error(`An error has occurred when sending the form: NO RESPONSE`);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error(`An error has occurred when sending the form: ${error.message}`);
            }
        }
    },
}));
