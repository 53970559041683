function storageHas(key) {
    return localStorage.getItem(key) !== null;
}
function storageGet(key) {
    return JSON.parse(localStorage.getItem(key));
}
function storageSet(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export { storageHas, storageGet, storageSet };
