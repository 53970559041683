import Alpine from 'alpinejs';
import anime from 'animejs/lib/anime.es.js';

Alpine.data('navbar', () => ({
    mobileOpen: false,
    animation: null,

    /**
     * Returns an animation instance for smoothly opening.
     *
     * @returns {Object}
     */
    openAnimationSmooth() {
        return anime({
            targets: this.$refs.navbarDropdown,

            // Properties to animate
            maxHeight: this.$refs.navbarDropdown.scrollHeight,

            // Animation settings
            duration: 200,
            autoplay: false,
            easing: 'easeInOutQuad',
        });
    },

    /**
     * Returns an animation instance for instantly (reduce animation) opening.
     *
     * @returns {Object}
     */
    openAnimationInstant() {
        return anime({
            targets: this.$refs.navbarDropdown,

            // Properties to animate
            maxHeight: this.$refs.navbarDropdown.scrollHeight,

            // Animation settings
            duration: 0,
            autoplay: false,
            easing: 'easeInOutQuad',
        });
    },

    /**
     * Returns an animation instance for smoothly closing.
     *
     * @returns {Object}
     */
    closeAnimationSmooth() {
        return anime({
            targets: this.$refs.navbarDropdown,

            // Properties to animate
            maxHeight: 0,

            // Animation settings
            duration: 200,
            autoplay: false,
            easing: 'easeInOutQuad',
        });
    },

    /**
     * Returns an animation instance for instantly (reduce animation) closing.
     *
     * @returns {Object}
     */
    closeAnimationInstant() {
        return anime({
            targets: this.$refs.navbarDropdown,

            // Properties to animate
            maxHeight: 0,

            // Animation settings
            duration: 0,
            autoplay: false,
            easing: 'easeInOutQuad',
        });
    },

    /**
     * Classes that need to be added/removed before an animation begins
     */
    animationBegin() {
        if (this.mobileOpen) {
            this.$refs.navbarDropdown.classList.remove('hidden');
        } else {
            this.$refs.navbarDropdown.classList.remove('!max-h-full');
        }
    },

    /**
     * Classes that need to be added/removed after an animation finished
     */
    animationComplete() {
        // Open state could have changed in the meantime, check again
        if (this.mobileOpen) {
            // Force a max-height state, instead of specific pixels
            this.$refs.navbarDropdown.classList.add('!max-h-full');
        } else {
            this.$refs.navbarDropdown.classList.add('hidden');
        }
    },

    /**
     * Toggles the animation for opening and closing the navigation
     *
     * @returns {Promise<void>}
     */
    async toggle() {
        this.mobileOpen = !this.mobileOpen;
        this.animationBegin();

        if (!this.animation) {
            const reduceAnimationQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
            // Check if the media query matches or is not available.
            if (!reduceAnimationQuery || reduceAnimationQuery.matches) {
                if (this.mobileOpen) {
                    this.animation = this.openAnimationInstant();
                } else {
                    this.animation = this.openAnimationInstant();
                }
            } else {
                if (this.mobileOpen) {
                    this.animation = this.openAnimationSmooth();
                } else {
                    this.animation = this.closeAnimationSmooth();
                }
            }

            this.animation.play();
        } else {
            // When toggling while animation is already running, reverse
            this.animation.reverse();
            this.animation.play();
        }

        await this.animation.finished;
        this.animationComplete();
        this.animation = null;
    },

    /**
     * If open, closes the navigation
     */
    async close() {
        // Only close if it's open
        if (this.mobileOpen) {
            await this.toggle();
        }
    },
}));
