import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
    let csrfCall = $.get("/api/csrf", function (data) {
        window.csrfTokenName = data.csrfTokenName;
        window.csrfTokenValue = data.csrfTokenValue;
        window.forms = data.forms;
    }, "json");

    $.when(csrfCall).then(function(data) {
        document.querySelector('input[name="CRAFT_CSRF_TOKEN"]').value = window.csrfTokenValue;
    });
});
