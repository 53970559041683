import './scss/styles.scss';

import './js/kautionen';

// Load Alpine Components
import Alpine from 'alpinejs';

import persist from '@alpinejs/persist';
Alpine.plugin(persist);

import './js/csrf';
import './js/cookie-consent';
import './js/navbar';
import './js/faq';
import './js/contact-form';

Alpine.start();

// Accept HMR
if (module && module.hot) {
    module.hot.accept();
}
